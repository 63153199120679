.navbar {
  background-color: #2a2622;
  color: white;
  padding: 2%;
  position: sticky;
  top: 0;
  width: 96%;
  z-index: 100;
  display: flex;
  align-items: center;
}

.logo {
  margin-left: 20px;
  flex-shrink: 0;
}

.logo-img {
  height: 60px;
  max-width: 100%;
}

.nav-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.nav-links {
  list-style-type: none;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  flex: 1;
}

.nav-links li {
  margin: 0 20px;
}

.nav-button {
  color: white;
  text-decoration: none;
  font-weight: bold;
  padding: 10px 15px;
  transition: background-color 0.3s, color 0.3s;
}

.nav-button:hover {
  background-color: #FCECD5;
  color: #190e00;
  border-radius: 5px;
}

.language-switcher {
  margin-right: 20px;
  font-size: 14px;
}

.language-button {
  cursor: pointer;
  font-weight: bold;
  color: #ffffff;
  background-color: #444;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  margin: 0 5px;
  transition: background-color 0.3s, color 0.3s;
}

.language-button:hover {
  background-color: #FCECD5;
  color: #190e00;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  margin-left: auto;
}

.hamburger {
  display: block;
  width: 25px;
  height: 3px;
  background-color: white;
  position: relative;
  transition: background-color 0.3s;
}

.hamburger::before,
.hamburger::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: white;
  transition: transform 0.3s;
}

.hamburger::before {
  top: -8px;
}

.hamburger::after {
  bottom: -8px;
}

@media screen and (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .nav-content {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #2a2622;
    flex-direction: column;
    padding: 20px 0;
    display: none;
  }

  .nav-content.active {
    display: flex;
  }

  .nav-links {
    flex-direction: column;
    width: 100%;
  }

  .nav-links li {
    margin: 10px 0;
  }

  .language-switcher {
    margin: 20px 0;
  }

  .logo-img {
    height: 40px;
  }
}

@media screen and (max-width: 480px) {
  .navbar {
    padding: 10px;
  }

  .logo {
    margin-left: 10px;
  }

  .logo-img {
    height: 35px;
  }
}