.footer {
    background-color: #2a2622;
    color: white;
    padding: 3%;
    text-align: center;
    position: relative;
    bottom: 0;
    width: 94%;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    list-style-type: none;
  }
  
  .footer-section {
    flex: 1;
    padding: 0 20px;
  }
  
  .social-links {
    list-style-type: none;
    padding: 0;
  }
  
  .social-links li {
    display: inline;
    margin-right: 10px;
  }
  
  .social-links a {
    color: white;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .social-links a:hover {
    color: #00aaff;
  }
  
  .footer-bottom {
    color: white;
    text-decoration: none;
    font-weight: bold;
    padding: 10px 15px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .footer-bottom:hover {
    background-color: #555;
    border-radius: 5px;
    color: #ffffff;
  }

  .footer-ai{
    color: rgb(255,255,255,0.3);
    font-size: smaller;
  }

  .footer-space{
    color: rgb(255,255,255,0);
  }

  @media screen and (max-width: 768px) {
    .footer-content {
      font-size: small;
    }
  }