
/* HeroCarousel.css */
.carousel-container {
  position: relative;
  width: 100%;
  height: 700px;
  overflow: hidden;
}

.carousel-layer-container {
  position: absolute;
  width: 150%;
  height: 30%;
  display: flex;
  gap: 30px;
  align-items: center;
  padding: 25px 30px;
}

.top-carousel {
  top: 0;
  animation: moveLeftContinuous 60s linear infinite;
}

.middle-carousel {
  top: 33%;
  animation: moveRightContinuous 60s linear infinite;
}

.bottom-carousel {
  top: 66%;
  animation: moveLeftContinuous 60s linear infinite;
}

.carousel-slide {
  flex: 0 0 15%;
  height: 90%;
  background-size: cover;
  background-position: center;
  transition: transform 0.8s ease-in-out, opacity 0.8s ease-in-out;
  opacity: 0.3;
  border-radius: 35px;
}

.carousel-slide.highlighted {
  opacity: 1;
  transform: scale(1.05);
}

@keyframes moveLeftContinuous {
  0% { transform: translateX(0); }
  100% { transform: translateX(-70%); }
}

@keyframes moveRightContinuous {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(0); }
}
.overlay-text {
  margin-top: 18%; /* Adjust this margin as needed for vertical placement */
  text-align: center; /* Center the text horizontally */
  color: white; /* Change as needed for visibility */
  font-size: 6.5em; /* Adjust font size */
  font-weight: bold;
  text-shadow: 20px 20px 20px rgba(0, 0, 0, 0.7); /* Optional shadow for better visibility */
  z-index: 10; /* Ensure it's above the carousel layers */
  filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.5)); /* Adds a shadow underneath the text */
  transform: rotateX(2deg) rotateY(-1deg) translateZ(3px); /* 3D effect */
  animation: float 3s ease-in-out infinite; /* Floating animation */
}

/* Responsive Adjustments for Mobile */
@media screen and (max-width: 1000px) {
  .carousel-container {
    height: 90%; /* Smaller height for mobile view */
  }

  .carousel-slide {
    height: 90%; /* Reduce image height for mobile */
  }

  .overlay-text {
    font-size: 4.5em; /* Smaller font size for mobile */
    margin-top: 15%; /* Adjust text placement */
  }
}

/* Responsive Adjustments for Mobile */
@media screen and (max-width: 480px) {
  .carousel-container {
    height: 90; /* Smaller height for mobile view */
  }

  .carousel-slide {
    height: 90%; /* Reduce image height for mobile */
  }

  .overlay-text {
    font-size: 4.5em; /* Smaller font size for mobile */
    margin-top: 45%; /* Adjust text placement */
  }
}
