.contact-page {
  padding: 50px;
  text-align: center;
}

.contact-form {
  max-width: 600px;
  margin: 0 auto;
  text-align: left;
}

.contact-form div {
  margin-bottom: 15px;
}

.contact-form label {
  display: block;
  margin-bottom: 5px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-form button {
  padding: 10px 20px;
  font-size: large;
  background-color: #89540b;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-form button:hover {
  background-color: #cc8b00;
}

.contact-info {
  margin-top: 30px;
  font-size: 1.2em;
  color: #333;
}

.contact-info p {
  margin: 10px 0;
}

.contact-info strong {
  color: #89540b;
}

.thank-you-message {
  text-align: center;
  margin-top: 50px;
}
