.service-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #C7DACC;
    padding: 20px;
    text-align: center;
    border-radius: 15px; /* Rounded corners */
    overflow: hidden;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    opacity: 0;
    transform: translateY(50px); /* For scroll animation (hidden at first) */
  }
  
  .service-item.show {
    opacity: 1;
    transform: translateY(0); /* Animates the item into view */
  }
  
  .service-background {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
    border-radius: 30px; /* Rounded corners for the background image */
    margin-bottom: 20px;
  }
  
  .service-item h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .service-item p {
    font-size: 1rem;
    color: #4c4437;
  }
  
  .service-item:hover {
    transform: translateY(-25px);
  }
  
  .service-item:active {
    transform: translateY(0);
  }
  