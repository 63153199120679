/* General Page Styles */
.homepage {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-align: center;
  background-color: #FCECD5;
  overflow-x: hidden;
}

/* Hero Section */
.hero {
  background-color: #898071;
  color: white;
  padding: 0;
  height: 86vh;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-content {
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.hero h1 {
  font-size: clamp(2rem, 5vw, 3rem);
  margin-bottom: 20px;
}

.hero-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  z-index: 2;
}

.hero-logo img {
  max-width: 90%;
  height: auto;
}

.hero-logo h1 {
  color: white;
  font-size: clamp(2rem, 8vw, 6rem);
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
}

/* Background Gallery */
.gallery-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  padding: 10px;
  opacity: 0.3;
  pointer-events: none;
}

.gallery-item {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

/* CTA Button */
.cta-button {
  background-color: #00aaff;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  font-size: clamp(16px, 4vw, 18px);
  border-radius: 5px;
  transition: background-color 0.3s ease;
  display: inline-block;
}

/* Services Section */
.services-section {
  padding: 40px 20px;
  position: relative;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.service {
  background-color: #FCECD5;
  padding: 20px;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}

.service img {
  width: 100%;
  height: auto;
  max-width: 300px;
  margin: 0 auto;
}
/* Cube Animation */
.service-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  pointer-events: none;
  z-index: 1; /* Changé de -1 à 1 */
  opacity: 0.3; /* Ajout d'une transparence */
}

.rotating-cube {
  position: relative;
  width: clamp(100px, 15vw, 200px); /* Agrandi pour plus de visibilité */
  height: clamp(100px, 15vw, 200px);
  transform-style: preserve-3d;
  transform-origin: center center;
  animation: rotateCubeDiagonal 8s infinite linear;
}

.cube-face {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  font-weight: bold;
  color: #000;
  background: rgba(255, 255, 255, 0.0); /* Léger fond blanc */
  border: 3px dotted #ffffff;
  backface-visibility: visible;
}

/* Positionnement des faces par rapport au centre */
.cube-face-front {
  transform: translateZ(calc(clamp(50px, 7.5vw, 100px)));
}

.cube-face-back {
  transform: rotateY(180deg) translateZ(calc(clamp(50px, 7.5vw, 100px)));
}

.cube-face-left {
  transform: rotateY(-90deg) translateZ(calc(clamp(50px, 7.5vw, 100px)));
}

.cube-face-right {
  transform: rotateY(90deg) translateZ(calc(clamp(50px, 7.5vw, 100px)));
}

.cube-face-top {
  transform: rotateX(90deg) translateZ(calc(clamp(50px, 7.5vw, 100px)));
}

.cube-face-bottom {
  transform: rotateX(-90deg) translateZ(calc(clamp(50px, 7.5vw, 100px)));
}

@keyframes rotateCubeDiagonal {
  0% {
    transform: rotateX(45deg) rotateY(0deg) rotateZ(45deg);
  }
  100% {
    transform: rotateX(45deg) rotateY(360deg) rotateZ(45deg);
  }
}

/* Media query pour le cube */
@media screen and (max-width: 768px) {
  .cube-face {
    border-width: 2px;
  }
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .hero {
    height: 70vh;
  }
  
  .hero-logo h1 {
    font-size: clamp(1.5rem, 6vw, 4rem);
  }
  
  .services-section {
    padding: 30px 10px;
  }
  
  .service-overlay {
    height: 120px;
  }
  
  .cube-face {
    border-width: 2px;
  }
}

@media screen and (max-width: 480px) {
  .hero-content {
    width: 95%;
    padding: 0 10px;
  }
  
  .services-grid {
    gap: 20px;
    padding: 10px;
  }
}

@media screen and (max-height: 500px) {
  .hero {
    height: 100vh;
  }
}