.about-page {
    padding: 50px;
    text-align: center;
  }
  
  .about-page h1 {
    margin-bottom: 20px;
  }
  
  .about-page p {
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.6;
  }
  
  .about-team {
    margin-top: 40px;
  }
  
  .about-team h2 {
    margin-bottom: 10px;
  }
  
  .about-team p {
    max-width: 600px;
    margin: 0 auto;
    line-height: 1.6;
  }
  